























import Vue, {PropType} from "vue";
import {AdvertCampaignData, AdvertCampaignSpotData} from "@/types";
import {mapActions, mapGetters} from "vuex";

export default Vue.extend({
  name: "advert-campaign-audio-spot-table-item" as string,

  props: {
    data: Object as PropType<AdvertCampaignSpotData>,
    advertCampaignId: Number as PropType<number>
  },

  data() {
    return {
      isPlaying: false,
      loader: false
    }
  },

  computed: {
    ...mapGetters("audioCommercialsModule", {
      advertCampaigns: "GET_ADVERT_CAMPAIGNS",
    }),
    insertSpotFile(): string {
      if (!this.data.file) return "";
      return `data:audio/wav;charset=utf-8;base64, ${this.data.file}`;
    },
    audioSpotDuration(): string {
      return this.data.duration.substring(3, 8);
    },
    filterSpotsInAllCampaignsExpectCurrent(): boolean {
      const allCampaigns: Array<AdvertCampaignData> = this.advertCampaigns.filter(a => a.id !== this.advertCampaignId);

      for (let campaign of allCampaigns) {
        if (!!campaign.spots.filter(s => s.canPlay).length) {
          return true;
        }
      }

      return false;
    },
    filterSpotsInCurrentCampaign(): boolean {
      const currentCampaign: AdvertCampaignData = this.advertCampaigns.find(a => a.id === this.advertCampaignId);

      return !!currentCampaign.spots.filter(v => v.id !== this.data.id && v.canPlay).length;
    },
    isAnySpotPlaying(): boolean {
      return this.filterSpotsInCurrentCampaign || this.filterSpotsInAllCampaignsExpectCurrent;
    }
  },

  methods: {
    ...mapActions("globalModule", {
      setSpotFile: "FETCH_STREAM"
    }),
    ...mapActions("audioCommercialsModule", {
      setCanPlayStatus: "SET_CAN_PLAY_STATUS"
    }),
    async playAudioSpot(id: number): Promise<void> {
      if (!this.insertSpotFile) {
        if (!!this.data.spot_last_updated) {
          this.loader = true;
          await this.setSpotFile({id: this.data.id, streamType: "spot", campaignId: this.advertCampaignId});
        }
      } else {
        const el: any = document.getElementById('audio-player' + id + this.advertCampaignId);
        this.isPlaying = true;
        setTimeout(() => {
          el.play();
        }, 0)
        this.setCanPlayStatus({ id: this.data.id, campaignId: this.advertCampaignId, state: true });
      }
    },
    pauseAudioSpot(id: number): void {
      const el: any = document.getElementById('audio-player' + id + this.advertCampaignId);
      this.isPlaying = false;
      setTimeout(() => {
        el.pause();
      }, 0)
      this.setCanPlayStatus({ id: this.data.id, campaignId: this.advertCampaignId, state: false });
    }
  },

  watch: {
    isPlaying: {
      handler(value: boolean): void {
        this.$refs["audio"+this.data.id+this.advertCampaignId].addEventListener('timeupdate',(e) => {
          if (this.$refs["audio"+this.data.id+this.advertCampaignId].currentTime >= parseInt(this.data.duration.slice(-2))) {
            this.isPlaying = false;
            this.setCanPlayStatus({ id: this.data.id, campaignId: this.advertCampaignId, state: false });
          }
        })
      }
    },
    insertSpotFile: {
      handler(value: string): void {
        if (value) {
          this.loader = false;

          setTimeout(() => {
            const el: any = document.getElementById('audio-player' + this.data.id + this.advertCampaignId);
            this.isPlaying = true;
            el.play();
          }, 0)
        }
      }
    },
    isAnySpotPlaying: {
      handler(value: boolean): void {
        if (value && this.data.canPlay) {
          this.pauseAudioSpot(this.data.id);
        }
      },
    }
  }
})
