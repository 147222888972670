


















































































import Vue, {PropType} from "vue";
import {AdvertCampaignData} from "@/types";
import AdvertCampaignAudioSpotTableItem from "@/components/audio-commercials/AdvertCampaignAudioSpotTableItem.vue";
import SelectVenuesModal from "@/components/modals/SelectVenuesModal.vue";
import DeleteItemConfirmModal from "@/components/modals/DeleteItemConfirmModal.vue";
import AddAdvertCampaignModal from "@/components/modals/AddAdvertCampaignModal.vue";
import {mapActions, mapGetters} from "vuex";
import moment from "moment";

export default Vue.extend({
   name: "advert-campaign-table-item",

  components: {
    "advert-campaign-audio-spot-table-item": AdvertCampaignAudioSpotTableItem,
    "select-venues-modal": SelectVenuesModal,
    "delete-item-confirm-modal": DeleteItemConfirmModal,
    "add-advert-campaign-modal": AddAdvertCampaignModal
  },

  props: {
    data: Object as PropType<AdvertCampaignData>,
    hasPermissions: Boolean as PropType<boolean>,
  },

  data() {
    return {
      showSpot: false,
      randomId: Math.floor(Math.random() * 100000)
    }
  },

  computed: {
    ...mapGetters("audioCommercialsModule", {
      advertCampaigns: "GET_ADVERT_CAMPAIGNS",
      audioSpots: "GET_AUDIO_SPOTS"
    }),
    ...mapGetters("venueSubscriptionModule", {
      venuesSubscription: "GET_VENUES_SUBSCRIPTION",
      subscriptionProducts: "GET_ALL_SUBSCRIPTION_PRODUCTS"
    }),
    selectedAdvertVenues(): Array<number> {
      return this.data.venues.map(v => v.id);
    },
    isAdvertSpotActive(){
      let nowTimestamp = moment().unix();
      let today = moment();
      let startTimestamp = moment(this.data.start_at + " " + "00:00").unix();
      let endTimestamp = moment(this.data.end_at + " " + "23:59").unix();

      if(nowTimestamp >= startTimestamp && nowTimestamp <= endTimestamp && this.data.active){
        if(today.isAfter(moment(this.data.start_time, 'hh:mm')) && today.isBefore(moment(this.data.end_time, 'hh:mm')) ){
         return true;
        } else {
          return false;
        }
      } else {
        return false
      }
    },
    advertCampaign(){
      if(!this.advertCampaigns) return;
      return this.advertCampaigns.find((q) => q.id == this.data.id)
    },
    isDeleteEnabled(){
      if(!this.data.venues.length || !this.advertCampaigns) return;
      const advertCampaign = this.advertCampaigns.find((q) => q.id == this.data.id)
      return advertCampaign.venues.length === this.data.venues.length
    }
  },

  methods: {
    ...mapActions("audioCommercialsModule", {
      removeAdvertCampaign: "DELETE_ADVERT_CAMPAIGNS"
    }),
    showSpots(): void {
      this.showSpot = !this.showSpot
    }
  }
})
