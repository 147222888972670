






























































































import Vue from "vue";
import Layout from "@/router/layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import ChooseVenuesModal from '../venue_evalution/popUp/chooseVenues.vue'
import PlusIcon from "@iconify-icons/ion/add-outline";
import AdvertCampaignTableItem from "@/components/audio-commercials/AdvertCampaignTableItem.vue";
import AudioCommercialsHeader from "@/components/audio-commercials/AudioCommercialsHeader.vue";
import AddAdvertCampaignModal from "@/components/modals/AddAdvertCampaignModal.vue";
import { AdvertCampaignData, AudioSpotData, VenueQuestionaryData } from "@/types";
import {mapActions, mapGetters} from "vuex";
import moment from "moment";

export default Vue.extend({
  name: "audio-commercials",

  components: {
    AddAdvertCampaignModal,
    AudioCommercialsHeader,
    Layout,
    PageHeader,
    ChooseVenuesModal,
    "audio-commercials-header": AudioCommercialsHeader,
    "advert-campaign-table-item": AdvertCampaignTableItem,
    "add-advert-campaign-modal": AddAdvertCampaignModal
  },

  data() {
    return {
      title: "Audio Commercials",
      icons: {
        add: PlusIcon,
      },
      selectVenuesModal: false,
      currentPage: 1,
      perPage: 10,
      items: [],
      randomId: Math.floor(Math.random() * 100000)
    }
  },

  async created(): Promise<void> {
    await this.checkLoggedUserRolePermission({ permissionId: 14 });
    await this.checkLoggedUserRolePermission({ permissionId: 15 });
  },

  async mounted(): Promise<void> {
    await this.setAdvertCampaigns({});
    await this.setPermissionAvailableCampaigns([...this.advertCampaigns])
    await this.setAudioSpots();
    await this.setVenuesSubscription();
    await this.setSubscriptionProducts();
  },

  computed: {
    ...mapGetters("audioCommercialsModule", {
      advertCampaigns: "GET_ADVERT_CAMPAIGNS",
      permissionAvailableAdvertCampaigns: "GET_PERMISSION_AVAILABLE_ADVERT_CAMPAIGNS",
      audioSpots: "GET_AUDIO_SPOTS",
      advertFilter: "GET_FILTER"
    }),
    ...mapGetters("venueSubscriptionModule", {
      venuesSubscription: "GET_VENUES_SUBSCRIPTION",
      subscriptionProducts: "GET_ALL_SUBSCRIPTION_PRODUCTS"
    }),
    ...mapGetters("companyModule", {
      companyId: "GET_COMPANY_ID"
    }),
    ...mapGetters("userModule", {
      hasManageAudioCampaignsPermissions: "GET_MANAGE_AUDIO_CAMPAIGNS_PERMISSION",
      hasManageAudioSpotsPermissions: "GET_MANAGE_AUDIO_SPOTS_PERMISSION"
    }),
    totalAdvertCampaigns(): number {
      return this.permissionAvailableAdvertCampaigns.length;
    },
    sortedAudioSpots(){
      if(!this.permissionAvailableAdvertCampaigns.length) return "";
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      let sortedAudioSpots = this.permissionAvailableAdvertCampaigns.sort((spot1, spot2) => {return spot2.id - spot1.id} )
      return sortedAudioSpots
    },
    advertCampaignsList(): Array<AdvertCampaignData> {
      return this.displayedFilters.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
    },
    displayedFilters(): Array<AdvertCampaignData>{
      return !this.advertFilter ? this.sortedAudioSpots : this.activeAdvertCampaigns
    },
    activeAdvertCampaigns(): Array<AdvertCampaignData> {
      let activeAdvertCampaigns = [];
      let nowTimestamp = moment().unix();
      this.permissionAvailableAdvertCampaigns.forEach((v: AdvertCampaignData) => {
        const startTimestamp = moment(v.start_at + " " + v.start_time).unix();
        const endTimestamp = moment(v.end_at + " " + v.end_time).unix();
        if(nowTimestamp >= startTimestamp && nowTimestamp <= endTimestamp && v.active){
          activeAdvertCampaigns.push(v)
        }
      })
      return activeAdvertCampaigns
    }
  },

  methods: {
    ...mapActions("companyModule", {
      setCompany: "SET_COMPANY",
    }),
    ...mapActions("audioCommercialsModule", {
      setAdvertCampaigns: "FETCH_ADVERT_CAMPAIGNS",
      setAudioSpots: "FETCH_AUDIO_SPOTS",
      setPermissionAvailableCampaigns: "FETCH_PERMISSION_AVAILABLE_CAMPAIGNS"
    }),
    ...mapActions("venueSubscriptionModule", {
      setVenuesSubscription: "FETCH_VENUES_SUBSCRIPTION",
      setSubscriptionProducts: "FETCH_SUBSCRIPTION_PRODUCTS"
    }),
    ...mapActions("venueModule", {
      setVenues: "FETCH_VENUES"
    }),
    ...mapActions("userModule", {
      checkLoggedUserRolePermission: "CHECK_LOGGED_USER_ROLE_PERMISSION"
    }),
    goSpotsList(): void {
      this.$router.push("/audio-commercials/list-of-spots")
    },
    resetPagination(): void {
      this.currentPage = 1;
    }
  },

  watch: {
    companyId: {
      async handler(newValue: number, oldValue: number): Promise<void> {
        if (newValue != oldValue) {
          await this.setVenues({fetchRatings: false, name: ''});
          await this.setAdvertCampaigns({});
          await this.setPermissionAvailableCampaigns([...this.advertCampaigns])
          await this.setAudioSpots();
          await this.setVenuesSubscription();
        }
      }
    },
    advertCampaigns: {
      async handler(newValue: number, oldValue: number): Promise<void> {
        if (newValue != oldValue) {
          await this.setPermissionAvailableCampaigns([...this.advertCampaigns])
        }
      }
    }
  }
})
