export const VENUESTESTDATA = [
  {
    'id': 103,
    'name': 'Devel Arpi',
    'description': '',
    'latitude': 48.140521,
    'longitude': 17.096562,
    'address1': 'Žižková 7',
    'city': 'Bratislava',
    'debug': false,
    'image_last_updated': 1595415845,
    'open': false,
    'timezone': 'Europe/Bratislava',
    'favorite': false,
    'voting': true,
    'joker': true,
    'songforyou': true,
    'distance': null
  },
  {
    'id': 107,
    'name': 'Devel 3oSoft',
    'description': '',
    'latitude': 48.15195,
    'longitude': 17.10686,
    'address1': 'Štefániková 864/4',
    'city': 'Bratislava',
    'debug': false,
    'image_last_updated': 1595417095,
    'open': true,
    'timezone': 'Europe/Bratislava',
    'favorite': false,
    'voting': true,
    'joker': true,
    'songforyou': true,
    'distance': null
  },
  {
    'id': 108,
    'name': 'DELIA I Rosum',
    'description': 'Delia - potraviny na ceste k vám.',
    'latitude': 48.155231,
    'longitude': 17.146563,
    'address1': 'Bajkalská 19/B',
    'city': 'Bratislava',
    'debug': false,
    'image_last_updated': 1583194334,
    'open': true,
    'timezone': 'Europe/Bratislava',
    'favorite': false,
    'voting': true,
    'joker': true,
    'songforyou': true,
    'distance': null
  },
  {
    'id': 109,
    'name': 'Rebuy Stars KE',
    'description': 'Spoločnosť Rebuy Stars je mladá, ale na poli herného priemyslu už etablovaná spoločnosť a so svojou sieťou herní a kasín je spojená s ďalšími značkami v danom segmente.',
    'latitude': 48.739726,
    'longitude': 21.267627,
    'address1': 'Trolejbusová 1',
    'city': 'Košice',
    'debug': false,
    'image_last_updated': 1583204237,
    'open': true,
    'timezone': 'Europe/Bratislava',
    'favorite': false,
    'voting': true,
    'joker': true,
    'songforyou': true,
    'distance': null
  },
  {
    'id': 110,
    'name': 'DELIA I Štúrová',
    'description': 'Delia - potraviny na ceste k vám.',
    'latitude': 48.142691,
    'longitude': 17.114434,
    'address1': 'Štúrova 23/11',
    'city': 'Bratislava',
    'debug': false,
    'image_last_updated': 1583194424,
    'open': true,
    'timezone': 'Europe/Bratislava',
    'favorite': false,
    'voting': true,
    'joker': true,
    'songforyou': true,
    'distance': null
  },
  {
    'id': 114,
    'name': 'DELIA I DNV',
    'description': 'Delia - potraviny na ceste k vám.',
    'latitude': 48.2040778,
    'longitude': 16.984082,
    'address1': 'Štefana Králika 5',
    'city': 'Bratislava',
    'debug': false,
    'image_last_updated': 1583194396,
    'open': true,
    'timezone': 'Europe/Bratislava',
    'favorite': false,
    'voting': true,
    'joker': true,
    'songforyou': true,
    'distance': null
  },
  {
    'id': 117,
    'name': 'SongorooM',
    'description': 'Tu môžete mať vlastný reklamný text- stručná charakteristika ap.\r\nText môže byť (zatiaľ) len neštrukturovaný bez obrázkov, či emotikonov do 50 slov.',
    'latitude': 48.15687,
    'longitude': 17.142073,
    'address1': 'Sartorisova 11',
    'city': 'Bratislava',
    'debug': false,
    'image_last_updated': 1585731156,
    'open': true,
    'timezone': 'Europe/Bratislava',
    'favorite': false,
    'voting': true,
    'joker': true,
    'songforyou': true,
    'distance': null
  },
  {
    'id': 126,
    'name': 'Tatra Banka BA VIVO',
    'description': 'Banka s inovatívnym prístupom',
    'latitude': 48.168242,
    'longitude': 17.138409,
    'address1': 'Vajnorská 100',
    'city': 'Bratislava',
    'debug': false,
    'image_last_updated': 1583158905,
    'open': true,
    'timezone': 'Europe/Bratislava',
    'favorite': false,
    'voting': true,
    'joker': true,
    'songforyou': true,
    'distance': null
  },
  {
    'id': 127,
    'name': 'DELIA | Polus',
    'description': 'Delia - potraviny na ceste k vám.',
    'latitude': 48.168727,
    'longitude': 17.138827,
    'address1': 'Vajnorská 100',
    'city': 'Bratislava',
    'debug': false,
    'image_last_updated': 1583194272,
    'open': true,
    'timezone': 'Europe/Bratislava',
    'favorite': false,
    'voting': true,
    'joker': true,
    'songforyou': true,
    'distance': null
  },
  {
    'id': 137,
    'name': 'DELIA I Rybárska brána',
    'description': 'Delia - potraviny na ceste k vám.',
    'latitude': 48.1425861,
    'longitude': 17.1090853,
    'address1': 'Rybárska brána 9',
    'city': 'Bratislava',
    'debug': false,
    'image_last_updated': 1583194466,
    'open': false,
    'timezone': 'Europe/Bratislava',
    'favorite': false,
    'voting': true,
    'joker': true,
    'songforyou': true,
    'distance': null
  },
  {
    'id': 138,
    'name': 'DELIA I Rybárska brána',
    'description': 'Delia - potraviny na ceste k vám.',
    'latitude': 48.1425861,
    'longitude': 17.1090853,
    'address1': 'Rybárska brána 9',
    'city': 'Bratislava',
    'debug': false,
    'image_last_updated': 1583194466,
    'open': false,
    'timezone': 'Europe/Bratislava',
    'favorite': false,
    'voting': true,
    'joker': true,
    'songforyou': true,
    'distance': null
  },
  {
    'id': 139,
    'name': 'DELIA I Rybárska brána',
    'description': 'Delia - potraviny na ceste k vám.',
    'latitude': 48.1425861,
    'longitude': 17.1090853,
    'address1': 'Rybárska brána 9',
    'city': 'Bratislava',
    'debug': false,
    'image_last_updated': 1583194466,
    'open': false,
    'timezone': 'Europe/Bratislava',
    'favorite': false,
    'voting': true,
    'joker': true,
    'songforyou': true,
    'distance': null
  },
  {
    'id': 140,
    'name': 'DELIA I Rybárska brána',
    'description': 'Delia - potraviny na ceste k vám.',
    'latitude': 48.1425861,
    'longitude': 17.1090853,
    'address1': 'Rybárska brána 9',
    'city': 'Bratislava',
    'debug': false,
    'image_last_updated': 1583194466,
    'open': false,
    'timezone': 'Europe/Bratislava',
    'favorite': false,
    'voting': true,
    'joker': true,
    'songforyou': true,
    'distance': null
  },
  {
    'id': 141,
    'name': 'DELIA I Rybárska brána',
    'description': 'Delia - potraviny na ceste k vám.',
    'latitude': 48.1425861,
    'longitude': 17.1090853,
    'address1': 'Rybárska brána 9',
    'city': 'Bratislava',
    'debug': false,
    'image_last_updated': 1583194466,
    'open': false,
    'timezone': 'Europe/Bratislava',
    'favorite': false,
    'voting': true,
    'joker': true,
    'songforyou': true,
    'distance': null
  },
  {
    'id': 142,
    'name': 'DELIA I Rybárska brána',
    'description': 'Delia - potraviny na ceste k vám.',
    'latitude': 48.1425861,
    'longitude': 17.1090853,
    'address1': 'Rybárska brána 9',
    'city': 'Bratislava',
    'debug': false,
    'image_last_updated': 1583194466,
    'open': false,
    'timezone': 'Europe/Bratislava',
    'favorite': false,
    'voting': true,
    'joker': true,
    'songforyou': true,
    'distance': null
  },
  {
    'id': 143,
    'name': 'DELIA I Rybárska brána',
    'description': 'Delia - potraviny na ceste k vám.',
    'latitude': 48.1425861,
    'longitude': 17.1090853,
    'address1': 'Rybárska brána 9',
    'city': 'Bratislava',
    'debug': false,
    'image_last_updated': 1583194466,
    'open': false,
    'timezone': 'Europe/Bratislava',
    'favorite': false,
    'voting': true,
    'joker': true,
    'songforyou': true,
    'distance': null
  },
  {
    'id': 144,
    'name': 'DELIA I Rybárska brána',
    'description': 'Delia - potraviny na ceste k vám.',
    'latitude': 48.1425861,
    'longitude': 17.1090853,
    'address1': 'Rybárska brána 9',
    'city': 'Bratislava',
    'debug': false,
    'image_last_updated': 1583194466,
    'open': false,
    'timezone': 'Europe/Bratislava',
    'favorite': false,
    'voting': true,
    'joker': true,
    'songforyou': true,
    'distance': null
  },
  {
    'id': 145,
    'name': 'DELIA I Rybárska brána',
    'description': 'Delia - potraviny na ceste k vám.',
    'latitude': 48.1425861,
    'longitude': 17.1090853,
    'address1': 'Rybárska brána 9',
    'city': 'Bratislava',
    'debug': false,
    'image_last_updated': 1583194466,
    'open': false,
    'timezone': 'Europe/Bratislava',
    'favorite': false,
    'voting': true,
    'joker': true,
    'songforyou': true,
    'distance': null
  },
  {
    'id': 146,
    'name': 'DELIA I Rybárska brána',
    'description': 'Delia - potraviny na ceste k vám.',
    'latitude': 48.1425861,
    'longitude': 17.1090853,
    'address1': 'Rybárska brána 9',
    'city': 'Bratislava',
    'debug': false,
    'image_last_updated': 1583194466,
    'open': false,
    'timezone': 'Europe/Bratislava',
    'favorite': false,
    'voting': true,
    'joker': true,
    'songforyou': true,
    'distance': null
  },
  {
    'id': 147,
    'name': 'DELIA I Rybárska brána',
    'description': 'Delia - potraviny na ceste k vám.',
    'latitude': 48.1425861,
    'longitude': 17.1090853,
    'address1': 'Rybárska brána 9',
    'city': 'Bratislava',
    'debug': false,
    'image_last_updated': 1583194466,
    'open': false,
    'timezone': 'Europe/Bratislava',
    'favorite': false,
    'voting': true,
    'joker': true,
    'songforyou': true,
    'distance': null
  },
  {
    'id': 148,
    'name': 'DELIA I Rybárska brána',
    'description': 'Delia - potraviny na ceste k vám.',
    'latitude': 48.1425861,
    'longitude': 17.1090853,
    'address1': 'Rybárska brána 9',
    'city': 'Bratislava',
    'debug': false,
    'image_last_updated': 1583194466,
    'open': false,
    'timezone': 'Europe/Bratislava',
    'favorite': false,
    'voting': true,
    'joker': true,
    'songforyou': true,
    'distance': null
  },
  {
    'id': 149,
    'name': 'DELIA I Rybárska brána',
    'description': 'Delia - potraviny na ceste k vám.',
    'latitude': 48.1425861,
    'longitude': 17.1090853,
    'address1': 'Rybárska brána 9',
    'city': 'Bratislava',
    'debug': false,
    'image_last_updated': 1583194466,
    'open': false,
    'timezone': 'Europe/Bratislava',
    'favorite': false,
    'voting': true,
    'joker': true,
    'songforyou': true,
    'distance': null
  },
  {
    'id': 150,
    'name': 'DELIA I Rybárska brána',
    'description': 'Delia - potraviny na ceste k vám.',
    'latitude': 48.1425861,
    'longitude': 17.1090853,
    'address1': 'Rybárska brána 9',
    'city': 'Bratislava',
    'debug': false,
    'image_last_updated': 1583194466,
    'open': false,
    'timezone': 'Europe/Bratislava',
    'favorite': false,
    'voting': true,
    'joker': true,
    'songforyou': true,
    'distance': null
  },
  {
    'id': 151,
    'name': 'DELIA I Rybárska brána',
    'description': 'Delia - potraviny na ceste k vám.',
    'latitude': 48.1425861,
    'longitude': 17.1090853,
    'address1': 'Rybárska brána 9',
    'city': 'Bratislava',
    'debug': false,
    'image_last_updated': 1583194466,
    'open': false,
    'timezone': 'Europe/Bratislava',
    'favorite': false,
    'voting': true,
    'joker': true,
    'songforyou': true,
    'distance': null
  },
  {
    'id': 152,
    'name': 'DELIA I Rybárska brána',
    'description': 'Delia - potraviny na ceste k vám.',
    'latitude': 48.1425861,
    'longitude': 17.1090853,
    'address1': 'Rybárska brána 9',
    'city': 'Bratislava',
    'debug': false,
    'image_last_updated': 1583194466,
    'open': false,
    'timezone': 'Europe/Bratislava',
    'favorite': false,
    'voting': true,
    'joker': true,
    'songforyou': true,
    'distance': null
  },
  {
    'id': 153,
    'name': 'DELIA I Rybárska brána',
    'description': 'Delia - potraviny na ceste k vám.',
    'latitude': 48.1425861,
    'longitude': 17.1090853,
    'address1': 'Rybárska brána 9',
    'city': 'Bratislava',
    'debug': false,
    'image_last_updated': 1583194466,
    'open': false,
    'timezone': 'Europe/Bratislava',
    'favorite': false,
    'voting': true,
    'joker': true,
    'songforyou': true,
    'distance': null
  },
  {
    'id': 154,
    'name': 'DELIA I Rybárska brána',
    'description': 'Delia - potraviny na ceste k vám.',
    'latitude': 48.1425861,
    'longitude': 17.1090853,
    'address1': 'Rybárska brána 9',
    'city': 'Bratislava',
    'debug': false,
    'image_last_updated': 1583194466,
    'open': false,
    'timezone': 'Europe/Bratislava',
    'favorite': false,
    'voting': true,
    'joker': true,
    'songforyou': true,
    'distance': null
  },
  {
    'id': 155,
    'name': 'DELIA I Rybárska brána',
    'description': 'Delia - potraviny na ceste k vám.',
    'latitude': 48.1425861,
    'longitude': 17.1090853,
    'address1': 'Rybárska brána 9',
    'city': 'Bratislava',
    'debug': false,
    'image_last_updated': 1583194466,
    'open': false,
    'timezone': 'Europe/Bratislava',
    'favorite': false,
    'voting': true,
    'joker': true,
    'songforyou': true,
    'distance': null
  },
  {
    'id': 158,
    'name': 'DELIA I Rybárska brána',
    'description': 'Delia - potraviny na ceste k vám.',
    'latitude': 48.1425861,
    'longitude': 17.1090853,
    'address1': 'Rybárska brána 9',
    'city': 'Bratislava',
    'debug': false,
    'image_last_updated': 1583194466,
    'open': false,
    'timezone': 'Europe/Bratislava',
    'favorite': false,
    'voting': true,
    'joker': true,
    'songforyou': true,
    'distance': null
  },
  {
    'id': 159,
    'name': 'DELIA I Rybárska brána',
    'description': 'Delia - potraviny na ceste k vám.',
    'latitude': 48.1425861,
    'longitude': 17.1090853,
    'address1': 'Rybárska brána 9',
    'city': 'Bratislava',
    'debug': false,
    'image_last_updated': 1583194466,
    'open': false,
    'timezone': 'Europe/Bratislava',
    'favorite': false,
    'voting': true,
    'joker': true,
    'songforyou': true,
    'distance': null
  },
  {
    'id': 160,
    'name': 'DELIA I Rybárska brána',
    'description': 'Delia - potraviny na ceste k vám.',
    'latitude': 48.1425861,
    'longitude': 17.1090853,
    'address1': 'Rybárska brána 9',
    'city': 'Bratislava',
    'debug': false,
    'image_last_updated': 1583194466,
    'open': false,
    'timezone': 'Europe/Bratislava',
    'favorite': false,
    'voting': true,
    'joker': true,
    'songforyou': true,
    'distance': null
  },
  {
    'id': 161,
    'name': 'DELIA I Rybárska brána',
    'description': 'Delia - potraviny na ceste k vám.',
    'latitude': 48.1425861,
    'longitude': 17.1090853,
    'address1': 'Rybárska brána 9',
    'city': 'Bratislava',
    'debug': false,
    'image_last_updated': 1583194466,
    'open': false,
    'timezone': 'Europe/Bratislava',
    'favorite': false,
    'voting': true,
    'joker': true,
    'songforyou': true,
    'distance': null
  },
  {
    'id': 162,
    'name': 'DELIA I Rybárska brána',
    'description': 'Delia - potraviny na ceste k vám.',
    'latitude': 48.1425861,
    'longitude': 17.1090853,
    'address1': 'Rybárska brána 9',
    'city': 'Bratislava',
    'debug': false,
    'image_last_updated': 1583194466,
    'open': false,
    'timezone': 'Europe/Bratislava',
    'favorite': false,
    'voting': true,
    'joker': true,
    'songforyou': true,
    'distance': null
  },
  {
    'id': 163,
    'name': 'DELIA I Rybárska brána',
    'description': 'Delia - potraviny na ceste k vám.',
    'latitude': 48.1425861,
    'longitude': 17.1090853,
    'address1': 'Rybárska brána 9',
    'city': 'Bratislava',
    'debug': false,
    'image_last_updated': 1583194466,
    'open': false,
    'timezone': 'Europe/Bratislava',
    'favorite': false,
    'voting': true,
    'joker': true,
    'songforyou': true,
    'distance': null
  },
  {
    'id': 164,
    'name': 'DELIA I Rybárska brána',
    'description': 'Delia - potraviny na ceste k vám.',
    'latitude': 48.1425861,
    'longitude': 17.1090853,
    'address1': 'Rybárska brána 9',
    'city': 'Bratislava',
    'debug': false,
    'image_last_updated': 1583194466,
    'open': false,
    'timezone': 'Europe/Bratislava',
    'favorite': false,
    'voting': true,
    'joker': true,
    'songforyou': true,
    'distance': null
  },
  {
    'id': 165,
    'name': 'DELIA I Rybárska brána',
    'description': 'Delia - potraviny na ceste k vám.',
    'latitude': 48.1425861,
    'longitude': 17.1090853,
    'address1': 'Rybárska brána 9',
    'city': 'Bratislava',
    'debug': false,
    'image_last_updated': 1583194466,
    'open': false,
    'timezone': 'Europe/Bratislava',
    'favorite': false,
    'voting': true,
    'joker': true,
    'songforyou': true,
    'distance': null
  },
  {
    'id': 166,
    'name': 'Rebuy Stars TT',
    'description': 'Spoločnosť Rebuy Stars je mladá, ale na poli herného priemyslu už etablovaná spoločnosť a so svojou sieťou herní a kasín je spojená s ďalšími značkami v danom segmente.',
    'latitude': 48.366588,
    'longitude': 17.612836,
    'address1': 'Obchodná 4',
    'city': 'Trnava',
    'debug': false,
    'image_last_updated': 1583204230,
    'open': true,
    'timezone': 'Europe/Bratislava',
    'favorite': false,
    'voting': true,
    'joker': true,
    'songforyou': true,
    'distance': null
  },
  {
    'id': 175,
    'name': 'Tatra Banka BA Bory Mall',
    'description': 'Banka s inovatívnym prístupom',
    'latitude': 48.206414,
    'longitude': 17.033706,
    'address1': 'Lamač 6780',
    'city': 'Bratislava',
    'debug': false,
    'image_last_updated': 1583159001,
    'open': true,
    'timezone': 'Europe/Bratislava',
    'favorite': false,
    'voting': true,
    'joker': true,
    'songforyou': true,
    'distance': null
  },
  {
    'id': 177,
    'name': 'Hotel Yasmin',
    'description': 'Nechajte sa zlákať eleganciou a účelnosťou moderného životného štýlu. Hľadáte komfortný biznis hotel v centre mesta či romantické miesto na víkendový oddych? Hotel Yasmin **** Košice je tým správnym miestom! Očarí Vás harmonickým interiérom, moderným vybavením a výnimočnou kvalitou služieb.',
    'latitude': 48.729634,
    'longitude': 21.257121,
    'address1': 'Tyršovo nábrežie 1',
    'city': 'Košice',
    'debug': false,
    'image_last_updated': 1583204000,
    'open': true,
    'timezone': 'Europe/Bratislava',
    'favorite': false,
    'voting': true,
    'joker': true,
    'songforyou': true,
    'distance': null
  },
  {
    'id': 182,
    'name': 'Špajza NR',
    'description': 'Špajza NR',
    'latitude': 48.3110595,
    'longitude': 18.0870282,
    'address1': 'Štefánikova 15',
    'city': 'Nitra',
    'debug': false,
    'image_last_updated': 1585654976,
    'open': true,
    'timezone': 'Europe/Bratislava',
    'favorite': false,
    'voting': true,
    'joker': true,
    'songforyou': true,
    'distance': null
  },
  {
    'id': 191,
    'name': 'O2 BA Eurovea',
    'description': '\'So Smart paušálmi si naplno užijete volania, dáta a skvelé aplikácie v našej 4G sieti. \r\nZískajte 10 GB navyše pre vybrané aplikácie a predplatné HBO GO, TIDAL, SME a ďalších.\r\nŽiadna viazanosť. Žiadne skryté poplatky. \r\nRovnaká cena do celej EÚ. \r\nExtra výhody. \r\nO2- férový operátor roka.\'',
    'latitude': 48.140223,
    'longitude': 17.122049,
    'address1': 'Pribinova 8',
    'city': 'Bratislava',
    'debug': false,
    'image_last_updated': 1582877824,
    'open': true,
    'timezone': 'Europe/Bratislava',
    'favorite': false,
    'voting': true,
    'joker': true,
    'songforyou': false,
    'distance': null
  },
  {
    'id': 192,
    'name': 'O2 BA Polus',
    'description': '\'So Smart paušálmi si naplno užijete volania, dáta a skvelé aplikácie v našej 4G sieti. \r\nZískajte 10 GB navyše pre vybrané aplikácie a predplatné HBO GO, TIDAL, SME a ďalších.\r\nŽiadna viazanosť. Žiadne skryté poplatky. \r\nRovnaká cena do celej EÚ. \r\nExtra výhody. \r\nO2- férový operátor roka.\'',
    'latitude': 48.167602,
    'longitude': 17.140404,
    'address1': 'Vajnorská 100',
    'city': 'Bratislava',
    'debug': false,
    'image_last_updated': 1582877665,
    'open': true,
    'timezone': 'Europe/Bratislava',
    'favorite': false,
    'voting': true,
    'joker': true,
    'songforyou': false,
    'distance': null
  },
  {
    'id': 193,
    'name': 'O2 BA Aupark',
    'description': '\'So Smart paušálmi si naplno užijete volania, dáta a skvelé aplikácie v našej 4G sieti. \r\nZískajte 10 GB navyše pre vybrané aplikácie a predplatné HBO GO, TIDAL, SME a ďalších.\r\nŽiadna viazanosť. Žiadne skryté poplatky. \r\nRovnaká cena do celej EÚ. \r\nExtra výhody. \r\nO2- férový operátor roka.\'',
    'latitude': 48.132192,
    'longitude': 17.109399,
    'address1': 'Einsteinova 18',
    'city': 'Bratislava',
    'debug': false,
    'image_last_updated': 1582877512,
    'open': true,
    'timezone': 'Europe/Bratislava',
    'favorite': false,
    'voting': true,
    'joker': true,
    'songforyou': false,
    'distance': null
  },
  {
    'id': 194,
    'name': 'O2 BA Centrál',
    'description': '\'So Smart paušálmi si naplno užijete volania, dáta a skvelé aplikácie v našej 4G sieti. \r\nZískajte 10 GB navyše pre vybrané aplikácie a predplatné HBO GO, TIDAL, SME a ďalších.\r\nŽiadna viazanosť. Žiadne skryté poplatky. \r\nRovnaká cena do celej EÚ. \r\nExtra výhody. \r\nO2- férový operátor roka.\'',
    'latitude': 48.156705,
    'longitude': 17.129648,
    'address1': 'Metodova 6',
    'city': 'Bratislava',
    'debug': false,
    'image_last_updated': 1582877441,
    'open': true,
    'timezone': 'Europe/Bratislava',
    'favorite': false,
    'voting': true,
    'joker': true,
    'songforyou': false,
    'distance': null
  },
  {
    'id': 196,
    'name': 'O2 BA Avion',
    'description': '\'So Smart paušálmi si naplno užijete volania, dáta a skvelé aplikácie v našej 4G sieti. \r\nZískajte 10 GB navyše pre vybrané aplikácie a predplatné HBO GO, TIDAL, SME a ďalších.\r\nŽiadna viazanosť. Žiadne skryté poplatky. \r\nRovnaká cena do celej EÚ. \r\nExtra výhody. \r\nO2- férový operátor roka.\'',
    'latitude': 48.20565,
    'longitude': 17.180018,
    'address1': 'Ivanská cesta 16',
    'city': 'Bratislava',
    'debug': false,
    'image_last_updated': 1582876356,
    'open': true,
    'timezone': 'Europe/Bratislava',
    'favorite': false,
    'voting': true,
    'joker': true,
    'songforyou': false,
    'distance': null
  },
  {
    'id': 198,
    'name': 'O2 BA Bory Mall',
    'description': '\'So Smart paušálmi si naplno užijete volania, dáta a skvelé aplikácie v našej 4G sieti. \r\nZískajte 10 GB navyše pre vybrané aplikácie a predplatné HBO GO, TIDAL, SME a ďalších.\r\nŽiadna viazanosť. Žiadne skryté poplatky. \r\nRovnaká cena do celej EÚ. \r\nExtra výhody. \r\nO2- férový operátor roka.\'',
    'latitude': 48.205606,
    'longitude': 17.03289,
    'address1': 'Lamač 6780',
    'city': 'Bratislava',
    'debug': false,
    'image_last_updated': 1582876290,
    'open': true,
    'timezone': 'Europe/Bratislava',
    'favorite': false,
    'voting': true,
    'joker': true,
    'songforyou': false,
    'distance': null
  }
];

export const SPOTSTESTDATA = {
  "links": {
    "pagination": {
      total: 50,
      per_page: 10,
      current_page: 1,
      last_page: 4,
      next_page_url: "",
      prev_page_url: "",
      from: 1,
      to: 15,
    }
  },
  "data": [
    {
      "id": 1,
      "name": "Spot 1",
      "music": "Music Author",
      "text": "Text author",
      "uploaded": "mail@mail.com",
      "duration": "00:30",
      "group_id": 1,
    },
    {
      "id": 2,
      "name": "Spot 2",
      "music": "Music Author",
      "text": "Text author",
      "uploaded": "mail@mail.com",
      "duration": "00:30",
      "group_id": 1,
    },
    {
      "id": 3,
      "name": "Spot",
      "music": "Music Author",
      "text": "Text author",
      "uploaded": "mail@mail.com",
      "duration": "00:30",
      "group_id": 1,
    },{
      "id": 4,
      "name": "Spot",
      "music": "Music Author",
      "text": "Text author",
      "uploaded": "mail@mail.com",
      "duration": "00:30",
      "group_id": 1,
    },{
      "id": 5,
      "name": "Spot",
      "music": "Music Author",
      "text": "Text author",
      "uploaded": "mail@mail.com",
      "duration": "00:30",
      "group_id": 1,
    },{
      "id": 6,
      "name": "Spot",
      "music": "Music Author",
      "text": "Text author",
      "uploaded": "mail@mail.com",
      "duration": "00:30",
      "group_id": 1,
    },
  {
    "id": 50,
    "name": "Spot",
    "music": "Music Author",
    "text": "Text author",
    "uploaded": "mail@mail.com",
    "duration": "00:30",
    "group_id": 3,
  }
]
}
