








































































import Vue, { PropType } from "vue";
import { VENUESTESTDATA } from '@/types/venuesTestData.ts'

export default Vue.extend({
  name: "chooseVenues",
  data() {
    return {
      venues: VENUESTESTDATA,
      resultColumns: [],
      allColumns: [],
      currentPage: 1,
      selected: [],
      status:'',
    }
  },
  props: {
    chooseVenuesModal: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    perPage: {
      type: Number as PropType<number>,
      default: 32
    },
    totalRows: {
      type: Number as PropType<number>,
      default: 180
    }
  },
  mounted() {
    // this.totalRows = this.venues.length

    this.allColumns = this.venues.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index/8)
      if(!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []
      }

      resultArray[chunkIndex].push(item)

      return resultArray
    }, [])

    this.resultColumns = this.allColumns.slice(this.currentPage - 1, this.currentPage * 4 )
  },
  methods: {
    setPage() {
      return this.resultColumns = this.allColumns.slice((this.currentPage - 1) * 4, this.currentPage * 4 )
    }
  }
});
