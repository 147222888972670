




























































































































































































import Vue, {PropType} from "vue";
import {mapActions, mapGetters} from "vuex";
import {
  AdvertCampaignData, AdvertCampaignSpotData, AdvertCampaignVenueData,
  AudioSpotData,
  SubscriptionProductsData,
  VenueData,
  VenueSubscriptionData
} from "@/types";
import SelectVenuesModal from "@/components/modals/SelectVenuesModal.vue";
import ValidationWarningModal from "@/components/modals/ValidationWarningModal.vue";
import {checkIfArrayIsUnique} from "@/utils/helpers";
import {ToggleButton} from "vue-js-toggle-button";
import DatePicker from "@/mixins/DatePicker";
import i18n from "@/i18n/i18n";
import { errorMessage } from "@/utils/messages-handlers";

export default Vue.extend({
  mixins: [DatePicker],
  name: "add-advert-campaign-modal" as string,

  components: {
    "select-venues-modal": SelectVenuesModal,
    "validation-warning-modal": ValidationWarningModal,
    ToggleButton
  },

  props: {
    isEdit: Boolean as PropType<boolean>,
    modalId: Number as PropType<number>,
    advertCampaignId: Number as PropType<number>,
    audioSpots: Array as PropType<Array<AudioSpotData>>,
    title: String as PropType<string>,
    venueSubscriptionData: Array as PropType<Array<VenueSubscriptionData>>,
    venueSubscriptionProductsData: Array as PropType<Array<SubscriptionProductsData>>,
    venueCampaigns: Array as PropType<Array<AdvertCampaignData>>,
    selectedIsActive: Boolean as PropType<boolean>,
    selectedCampaignName: String as PropType<string>,
    selectedCampaignDurationFrom: String as PropType<string>,
    selectedCampaignDurationTo: String as PropType<string>,
    selectedCampaignDurationFromTime: String as PropType<string>,
    selectedCampaignDurationToTime: String as PropType<string>,
    selectedCampaignSpotFrequency: Number as PropType<number>,
    selectedCampaignSpots: Array as PropType<Array<AdvertCampaignSpotData>>,
    selectedCampaignVenues: Array as PropType<Array<AdvertCampaignVenueData>>,
    isAdvertSpotEditModal: {
      default: false,
      type: Boolean
    }
  },


  data() {
    return {
      campaignName: this.isEdit ? this.selectedCampaignName : "",
      campaignDuration: this.isEdit ? [this.selectedCampaignDurationFrom,this.selectedCampaignDurationTo] : [] as Array<string>,
      campaignTimeFrom: this.isEdit ? this.selectedCampaignDurationFromTime : "",
      campaignTimeTo: this.isEdit ? this.selectedCampaignDurationToTime : "",
      selectedSpotFrequency: this.isEdit ? this.selectedCampaignSpotFrequency : null,
      isActive: this.isEdit ? this.selectedIsActive : true,
      frequencyOptions: [5,10,15,20],
      audioSpotFields: [
        {
          id: 0,
          selectedAudioSpot: null
        }
      ],
      selectedAudioSpots: [] as Array<number>,
      selectedVenues: [] as Array<number>,
      allowedVenues: [] as Array<VenueData>,
      randomId: Math.floor(Math.random() * 100000),
      validationMessage: "",
      lang: DatePicker,
      i18: i18n,
      infoDisabled: "Please note that this venue has already active audio campaing for date and time selected. If you want to add this venue into current campaing, please deselect it from previous campaign or adjust dates and times of active campaigns to avoid their overlapping.",
      infoAudioMessages: "The additional service called Audio Messages is not ordered for this venue. You can add this service to venue's subscription plan anytime you want.",
    }
  },
  computed: {
    ...mapGetters("companyModule", {
      companyId: "GET_COMPANY_ID",
    }),
    ...mapGetters("venueModule", {
      venues: "GET_VENUES"
    }),
    ...mapGetters("audioCommercialsModule", {
      audioSpotLoader: "GET_AUDIO_SPOT_LOADER",
      advertSpotAvailableVenues: "GET_ADVERT_SPOT_AVAILABLE_VENUES"
    }),
    filterPremiumVenues(): Array<VenueData> {
      const premiumVenues: Array<VenueData> = [] as Array<VenueData>;

      if (!this.venueSubscriptionData.length) return;

      this.venues.forEach((v: VenueData) => {
        const venueSubscription: VenueSubscriptionData = this.venueSubscriptionData.find(s => s.venueId === v.id);

        if(venueSubscription){
          const venueSubscriptionProduct: SubscriptionProductsData = this.venueSubscriptionProductsData.find(p => p.id === venueSubscription.stripeProductWithPriceId);

          if (venueSubscriptionProduct.songorooPackageType == 2) premiumVenues.push(v);
        }

      })

      return premiumVenues
    },

    selectedSurveyVenues(): Array<number> {
      if (!this.selectedCampaignVenues) return [];
      return this.selectedCampaignVenues.map(v => v.id);
    },
    filteredCampaignsVenues(): Array<number> {
      let filteredVenues: Array<any> = [] as Array<any>;
      this.advertSpotAvailableVenues.forEach((v: any) => {
        if(v.campaign_active){
          filteredVenues.push(v)
        }
      })
      if (this.isEdit) {
        let filteredVenuesWithSelected = [];

        filteredVenues.forEach((v: any) => {
          if (!this.selectedCampaignVenues.find(i => i.id === v.id)) {
            filteredVenuesWithSelected.push(v)
          }
        })
        filteredVenues = filteredVenuesWithSelected;
      }

      return filteredVenues.map(v=> v.id);
    },
    isSelectVenueDisabled(): boolean{
      return this.campaignDuration.length == 0 || (this.campaignTimeFrom == '' || this.campaignTimeFrom == null) || (this.campaignTimeTo == '' || this.campaignTimeTo == null)
    },
    areFieldsValid(): boolean {
      return !!this.campaignName && !!this.campaignTimeFrom && !!this.campaignTimeTo && !!this.selectedSpotFrequency && !!this.campaignDuration.length;
    },
    filterDeactivatedVenues(){
      const deactivatedVenues: Array<number> = [] as Array<number>;

      if (!this.venueSubscriptionData.length || !this.venueSubscriptionProductsData.length) return;
      let nowTimestamp = Math.floor(Date.now() / 1000);
      this.venues.forEach((v: VenueData) => {
        const venueSubscription: VenueSubscriptionData = this.venueSubscriptionData.find(s => s.venueId === v.id);
        if(venueSubscription){
          if (Number(venueSubscription.subscriptionEndsAt) <  nowTimestamp || venueSubscription.subscriptionStatus == 'canceled') deactivatedVenues.push(v.id);
        }
      })
      return deactivatedVenues
    },
  },

  methods: {
    ...mapActions("audioCommercialsModule", {
      createAdvertCampaign: "CREATE_ADVERT_CAMPAIGNS",
      editAdvertCampaign: "EDIT_ADVERT_CAMPAIGNS",
      setAdvertSpotAvailableVenues: "FETCH_AVAILABLE_VENUE_QUESTIONARY"
    }),
    addField(): void {
      const lastItem = [...this.audioSpotFields].pop();
      this.audioSpotFields.push({ id: lastItem.id + 1, selectedAudioSpot: null });
    },
    removeField(id: number): void {
      this.audioSpotFields = this.audioSpotFields.filter(a => a.id !== id);
    },
    setSelectedVenues(value: Array<number>): void {
      this.selectedVenues = value;
    },
    setAllowedVenues(value: Array<VenueData>): void {
      this.allowedVenues = value;
    },
    setIsActiveDisabled(): void {
      this.isActiveDisabled = this.checkIfActiveButtonIsDisabled();
    },
    getAvailableVenues(){
      if(this.isAdvertSpotEditModal){
        this.setAdvertSpotAvailableVenues({dateFrom: this.campaignDuration[0], dateTo: this.campaignDuration[1], startTime: this.campaignTimeFrom, endTime: this.campaignTimeTo});
      } else if(this.campaignDuration[0] && this.campaignDuration[1] && this.campaignTimeFrom && this.campaignTimeTo){
        this.setAdvertSpotAvailableVenues({dateFrom: this.campaignDuration[0], dateTo: this.campaignDuration[1], startTime: this.campaignTimeFrom, endTime: this.campaignTimeTo});
      }
    },
    disabledRange(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date < today;
    },

    async createCampaign(): Promise<void> {
      if (!this.areFieldsValid && this.selectedVenues.length) {
        this.validationMessage = this.$t('All fields are required.');
        this.$bvModal.show("validation-warning-modal" + this.randomId);
        return;
      }

      if (this.allowedVenues.length == 0){
        this.validationMessage = this.$t('No venues available for this campaing.');
        this.$bvModal.show("validation-warning-modal" + this.randomId);
        return;
      }

      if (!this.selectedVenues.length) {
        this.validationMessage = this.$t('Please select venues.');
        this.$bvModal.show("validation-warning-modal" + this.randomId);
        return;
      }


      this.selectedAudioSpots = [];

      if (this.audioSpotFields.find(s => !s.selectedAudioSpot)) {
        this.validationMessage = this.$t('Please select audio spot.');
        this.$bvModal.show("validation-warning-modal" + this.randomId);
        return;
      } else {
        this.audioSpotFields.forEach((s) => {
          this.selectedAudioSpots.push(s.selectedAudioSpot.id);
        })
      }

      if(this.campaignDuration[0] == this.campaignDuration[1] && this.campaignTimeFrom >= this.campaignTimeTo){
        this.validationMessage = this.$t('Campaign needs to start before its end.');
        this.$bvModal.show("validation-warning-modal" + this.randomId);
        return;
      }

      if (!checkIfArrayIsUnique(this.selectedAudioSpots)) {
        this.validationMessage = this.$t('Remove duplicate audio spots.');
        this.$bvModal.show("validation-warning-modal" + this.randomId);
        return;
      }

      const payload: object = {
        name: this.campaignName,
        start_at: this.campaignDuration[0],
        end_at: this.campaignDuration[1],
        start_time: this.campaignTimeFrom,
        end_time: this.campaignTimeTo,
        frequency: this.selectedSpotFrequency,
        active: this.isActive,
        venue_owner_id: this.companyId,
        spot_ids: this.selectedAudioSpots,
        venue_ids: this.selectedVenues
      }

      if (this.isEdit) {
        await this.editAdvertCampaign({payload: payload, id: this.advertCampaignId});
      } else {
        await this.createAdvertCampaign(payload);

        this.campaignName = "";
        this.campaignDuration = [] as Array<string>;
        this.campaignTimeFrom = "";
        this.campaignTimeTo = "";
        this.selectedSpotFrequency = null;
        this.audioSpotFields = [{ id: 0, selectedAudioSpot: null }];
      }
      this.$bvModal.hide('add-new-campaign' + this.modalId);
    }
  },

  watch: {
    selectedCampaignSpots: {
      immediate: true,
      handler(value: Array<AdvertCampaignSpotData>): void {
        if (value) {
          this.audioSpotFields = [];

          value.forEach((v, i: number) => {
            this.audioSpotFields.push({ id: i++, selectedAudioSpot: v })
          })
        }
      }
    },
    selectedCampaignVenues: {
      immediate: true,
      handler(value: Array<AdvertCampaignVenueData>): void {
        if (value) {
          this.selectedVenues = value.map(v => v.id);
        }
      }
    },
    campaignDuration: {
      async handler() {
        if(this.campaignDuration[0] && this.campaignDuration[1] && this.campaignTimeFrom && this.campaignTimeTo){
          await this.setAdvertSpotAvailableVenues({dateFrom: this.campaignDuration[0], dateTo: this.campaignDuration[1], startTime: this.campaignTimeFrom, endTime: this.campaignTimeTo});
        }
      }
    },
    campaignTimeFrom: {
      async handler() {
        if(this.campaignDuration[0] && this.campaignDuration[1] && this.campaignTimeFrom && this.campaignTimeTo){
          await this.setAdvertSpotAvailableVenues({dateFrom: this.campaignDuration[0], dateTo: this.campaignDuration[1], startTime: this.campaignTimeFrom, endTime: this.campaignTimeTo});
          }
        }
    },
    campaignTimeTo: {
      async handler() {
        if(this.campaignDuration[0] && this.campaignDuration[1] && this.campaignTimeFrom && this.campaignTimeTo) {
          await this.setAdvertSpotAvailableVenues({
            dateFrom: this.campaignDuration[0],
            dateTo: this.campaignDuration[1],
            startTime: this.campaignTimeFrom,
            endTime: this.campaignTimeTo
          });
        }
        }
    }
  }
})
